// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollup {
    position: fixed;
    right: 2.5rem;
    bottom: -20%;
    background-color: var(--title-color);
    opacity: 0.8;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup__icon {
    font-size: 1.5rem;
    color: var(--container-color);
}

.scrollup:hover {
    background-color: var(--title-color-dark);
}

/* Show Scroll */
.show-scroll {
    bottom: 3rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup {
        right: 1.5rem;
        padding: 0.25rem 0.4rem;
    }

    .scrollup__icon {
        font-size: 1.25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .show-scroll {
        bottom: 4rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .scrollup {
        right: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/scrollup/scrollup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,oCAAoC;IACpC,YAAY;IACZ,sBAAsB;IACtB,qBAAqB;IACrB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA,gBAAgB;AAChB;IACI,YAAY;AAChB;;AAEA,8CAA8C;AAC9C,sBAAsB;AACtB;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;IACtB;AACJ;;AAEA,uBAAuB;AACvB;IACI;QACI,YAAY;IAChB;AACJ;;AAEA,sBAAsB;AACtB;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".scrollup {\n    position: fixed;\n    right: 2.5rem;\n    bottom: -20%;\n    background-color: var(--title-color);\n    opacity: 0.8;\n    padding: 0.3rem 0.5rem;\n    border-radius: 0.4rem;\n    z-index: var(--z-tooltip);\n    transition: .4s;\n}\n\n.scrollup__icon {\n    font-size: 1.5rem;\n    color: var(--container-color);\n}\n\n.scrollup:hover {\n    background-color: var(--title-color-dark);\n}\n\n/* Show Scroll */\n.show-scroll {\n    bottom: 3rem;\n}\n\n/*=============== BREAKPOINTS ===============*/\n/* For large devices */\n@media screen and (max-width: 992px) {\n    .scrollup {\n        right: 1.5rem;\n        padding: 0.25rem 0.4rem;\n    }\n\n    .scrollup__icon {\n        font-size: 1.25rem;\n    }\n}\n\n/* For medium devices */\n@media screen and (max-width: 768px) {\n    .show-scroll {\n        bottom: 4rem;\n    }\n}\n\n/* For small devices */\n@media screen and (max-width: 350px) {\n    .scrollup {\n        right: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
